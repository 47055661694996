@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  display: none;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
.customeBackground {
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 99%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* CARDS */
.mainCard {
  perspective: 1000px;
  transition: transform 1s, opacity 0.8s;
  transform-style: preserve-3d;
  opacity: 0;
  transform: rotateY(240deg);
}
.mainCardRes {
  perspective: 1000px;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}
.flip {
  opacity: 1;
  transform: rotateY(360deg);
}
.flipRes {
  transform: rotateY(360deg);
}

.my-custom-captcha {
  height: 43px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../src/assets/images/About\ Us/CapchaBack.png');
  border-radius: 16px;
}


#work-container #featured-work-text {
  align-self: flex-start;
  position: sticky;
  top: 0;
  width: 45%;
  margin-right: 1.25rem;
  height: 1080px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
#work-container #product-work-text {
  height: 1080px;
}
#work-container .discover-text {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
}

/* STEPPER COMPOENET CSS */
.stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stepOuter {
  width: 38px;
  height: 38px;
  background-color: #ffffff;
  color: #00a0fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0;
}

.step {
  width: 38px;
  height: 38px;
  background-color: white;
  color: #00a0fc;
  display: flex;
  border-radius: 50%;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  transition: background-color 3s, height 3s;
  /* opacity: 0.5; */
}

.step.active,
.step.visited {
  width: 38px;
  height: 38px;
  background-color: #00a0fc;
  color: #fff;
  opacity: 1;
}

.ProgressOuter {
  width: 4px;
  height: 36px;
  background-color: #ffffff;
}
.ProgressInner {
  width: 4px;
  background-color: #00a0fc;
  height: 0px;
  -webkit-transition: height 1s;
  transition: height 1s;
}
.ProgressInner.activeProgressInner,
.ProgressInner.visitedProgress {
  height: 36px;
}

.step-content {
  margin-left: 20px;
}
/* /////////////// */

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in.loaded {
  opacity: 1;
}

/* styles.css */
.anime-fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
}
.backImage{
  background-image: url('../src/assets/images/Extension/Frame3.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
input[type="checkbox"]{
  width: 20px;
  height: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* styles.css */
.anime-pop-up {
  opacity: 0;
  transform: scale(0.5);
  /* Initial scale, element starts small */
  animation: popUp 0.3s ease-in-out forwards;
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
    /* Start small */
  }

  100% {
    opacity: 1;
    transform: scale(1);
    /* End at full size */
  }
}

.anime-pop-up-delayed {
  opacity: 0;
  transform: scale(0.5);
  /* Initial scale, element starts small */
  animation: popUp 0.9s ease-in-out forwards;
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
    /* Start small */
  }

  100% {
    opacity: 1;
    transform: scale(1);
    /* End at full size */
  }
}
.right-container .swiper {
  padding-bottom: 40px !important;
}
.swiper-pagination {
  text-align: start !important;
}
.start-rating svg {
  width: 26px !important;
  height: 26px !important;
}
.mySwiperPlan .swiper-pagination {
  text-align: center !important;
  bottom: -5px !important;
}
.mySwiperPlan .swiper-wrapper{
  padding-bottom: 50px !important;
}

/* FAQ */
.answerSection {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease, max-height 0.4s ease; /* Adjust the duration and timing function as needed */
}

/* Open state */
.answerSection.open {
  opacity: 1;
  max-height: 200px; /* Adjust the value as needed */
}

.Phone input::placeholder {
  color: #9a9a9a !important;
}

.PhoneInput {
  width: 100% !important;
}

.PhoneInputCountry {
  display: flex !important;
  border: 1px solid #dfe4ec !important;
  padding: 16px 15px !important;
  border-radius: 6px !important;
}

.not-error .PhoneInputInput {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border: 1px solid #dfe4ec;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'DM Sans', sans-serif;
  color: #19224c !important;
  font-weight: 600;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.error-class .PhoneInputInput {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border: 1px solid #ff0000 !important;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'DM Sans', sans-serif;
  color: #19224c !important;
  font-weight: 600;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.not-error-isAuth .PhoneInputInput {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border: 1px solid #DFE4EC;
  padding: 12px 12px !important;
  font-size: 14px !important;
  font-family: 'DM Sans', sans-serif;
  color: #19224c !important;
  font-weight: 400;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.error-class-isAuth .PhoneInputInput {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  border: 1px solid #ff0000 !important;
  padding: 12px 12px !important;
  font-size: 16px !important;
  font-family: 'DM Sans', sans-serif;
  color: #19224c !important;
  font-weight: 600;
  width: 100% !important;
  outline: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
  width: 27px !important;
}

.PhoneInputCountrySelectArrow {
  width: 8px !important;
  height: 8px !important;
  opacity: 1 !important;
  border-right: 1px solid !important;
  border-bottom: 1px solid !important;
  border-color: black !important;
  margin-left: 8px !important;
  margin-bottom: 4px !important;
}
.PhoneInputCountryFlag {
  display: none;
}
.captchaClass {
  display: flex !important;
}
/* #canv {
  width: 200px;
} */
.clip-path-class {
  clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
}
.clip-path-class-upper {
  clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
}

/* Legal BOX SHADOW */
.legal-shadow{
  box-shadow: 0px 8px 40px 0px #19224C1A;
}
.mobilemenu-shadow{
  box-shadow: 0px 4px 30px 0px #0000000D;
}
.cookie-switch{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.hero-button-shadow{
  box-shadow: 4px 8px 24px 0px #DFE4EC80;
}
.icon-shadow{
  box-shadow: '0px 12px 30px 0px #3C425714' 
}



/* SWITCH */
.react-switch-handle {
  box-shadow: 0px 1px 3px 0px #bcc1d2;
}
/* CHART ANIMATION  */
@keyframes fillAnimation {
  from {
    stroke-dasharray: 0, 1000;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 1000, 0;
    stroke-dashoffset: -1000;
  }
}

.chart-animation-yellow, .chart-animation-blue {
  animation: fillAnimation 4s ease forwards;
}


.chart-class canvas{
  width: 100% !important;
  height: 190px !important;
}
.chart-class-payment canvas{
  width: 100% !important;
  /* height: 200 !important; */
}
.chart-class-ai canvas{
  width: 100% !important;
  /* height: 250px !important; */
}

.underLineText{
  text-decoration: underline;
  color: #1678F2;
  cursor: pointer;
}








/* Define the keyframes for the slide animations */
/* @keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(20%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-10%);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.8s forwards;
}

.slide-out {
  animation: slideOut 0.8s forwards;
} */


 
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(60%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-47%);
    opacity: 1;
  }
}
@keyframes slideOutBack {
  from {
    transform: translateX(-47%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Apply the animations */
 .slide-in {
  animation: slideIn 0.8s forwards;
}

.slide-out {
  animation: slideOut 0.8s forwards;
} 
.slide-out-back {
  animation: slideOutBack 0.8s forwards;
} 


/* Product PaymentLine Animation  */

@keyframes drawLine {
  from {
    stroke-dashoffset: 3500; 
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animatedLine {
  animation: drawLine 8s forwards;
}

@keyframes drawLine1 {
  from {
    stroke-dashoffset: 762; 
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animatedStraitLine {
  animation: drawLine1 8s forwards;
}





@keyframes drawSyncLine {
  from {
    stroke-dashoffset: 3500; 
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animatedSyncLine {
  animation: drawSyncLine 8s forwards;
}

@keyframes shadowFadeproduct {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 0px 4px 20px 0px #00A0FC33;    
  }
}

.shadow-animate-product {
  animation: shadowFadeproduct 1s forwards;
}



/* LOADER */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.animate-spinner {
  animation: spinner 1s linear infinite;
}

@media (max-width: 1920px) {
  #work-container #featured-work-text {
    height: 100vh;
  }
  #work-container #product-work-text {
    height: 100vh;
  }
}

@media (max-width: 1300px) {
  #work-container #featured-work-text {
    max-width: 980px;
  }
  #work-container #product-work-text {
    max-width: 980px;
  }
  #work-container .discover-text {
    max-width: 980px;
  }
  .clip-path-class {
    clip-path: polygon(0 8%, 100% 0%, 100% 92%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 8%, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (max-width: 980px) {
  #work-container #featured-work-text {
    padding-left: 40px;
    padding-right: 40px;
  }
  #work-container #product-work-text {
    padding-left: 40px;
    padding-right: 40px;
  }
  .stepper {
    flex-direction: row;
    justify-content: center;
  }
  .ProgressOuter {
    width: 40px;
    height: 4px;
    /* background-color: #D9EBFF; */
    transition: background-color 1s;
  }
  .ProgressInner {
    width: 0px;
    background-color: #00a0fc;
    height: 4px;
    -webkit-transition: width 1s;
    transition: width 1s;
  }
  .ProgressInner.activeProgressInner,
  .ProgressInner.visitedProgress {
    width: 40px;
    height: 4px;
  }
  .swiper-pagination {
    text-align: center !important;
  }
  .clip-path-class {
    clip-path: polygon(0 6%, 100% 0%, 100% 94%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 6%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@media (max-width: 600px) {
  .clip-path-class {
    clip-path: polygon(0 3%, 100% 0%, 100% 97%, 0% 100%);
  }
  .clip-path-class-upper {
    clip-path: polygon(0 2.5%, 100% 0%, 100% 100%, 0% 100%);
  }
  .answerSection.open{
    max-height: 350px;
  }
   .my-custom-captcha {
    width: 200px;
    height: 40px;
  }
}
@media (max-width: 350px) {
  .ProgressOuter {
    width: 32px;
  }
  .ProgressInner.activeProgressInner,
  .ProgressInner.visitedProgress {
    width: 32px;
    height: 4px;
  }
  .clip-path-class-upper {
    clip-path: polygon(0 2%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@layer components {
  .F-JC-AI-CENTER {
    @apply flex items-center justify-center;
  }
  .headingText {
    @apply text-headingTxt font-DMSansFont font-bold text-LightPrimary max_xl:text-headingXl leading-[90px] max_xl:leading-[78px] max_md:leading-[52px] max_sm:leading-[40px] max_md:text-text3Xl max_sm:text-text2xl;
  }
  .commonHeadingFea{
    @apply font-DMSansFont font-bold text-LightPrimary text-[50px] leading-[65px] max_md:text-[40px] max_md:leading-[52px] max_sm:text-[30px] max_sm:leading-[40px]
  }
  .paragraphText {
    @apply text-subHeadingTxt font-DMSansFont font-medium text-LightTextParagraph max_xl:text-subHeadingXl max_md:text-headingNormal max_sm:text-textSmall;
  }
  .subHeadingText {
    @apply text-subHeadingTxt font-DMSansFont font-bold text-LightPrimary max_sm:text-headingNormal;
  }
  .subParagraphText {
    @apply text-headingNormal font-DMSansFont font-medium text-LightTextParagraph max_sm:text-textSmall;
  }
  .smallText16 {
    @apply text-textSmall text-LightTextParagraph font-DMSansFont font-medium;
  }
  .smallText16Normal {
    @apply text-textSmall text-LightSecondary font-DMSansFont font-normal;
  }
  .smallText18 {
    @apply text-textMedium font-DMSansFont;
  }
  .text40class {
    @apply text-text3Xl text-ButtonPrimary font-bold max_xl:text-[38px] font-DMSansFont max_md:text-[32px] max_sm:text-subHeadingTxt
    leading-[60px] max_md:leading-[45px] max_sm:leading-[35px];
  }
  .text50 {
    @apply text-text4xl font-DMSansFont leading-[65px] text-LightPrimary font-bold tracking-[-1px] max_md:text-text3Xl max_md:leading-[52px] max_sm:leading-[35px] max_sm:text-subHeadingTxt;
  }
  .text50Product {
    @apply text-text4xl font-DMSansFont leading-[65px] text-LightPrimary font-bold tracking-[-1px] max_md:text-[32px] max_md:leading-[41px] max_sm:leading-[31px] max_sm:text-subHeadingTxt 
  }
  .text22 {
    @apply text-subHeadingXl font-DMSansFont font-bold text-LightPrimary max_sm:text-headingNormal;
  }
  .labelClass{
    @apply text-textExtraSmall font-DMSansFont font-bold text-LightSecondary text-start w-full uppercase mb-[3px]
  }
}

.path-animation {
  animation: draw-line 8s forwards; /* Adjust time as needed */
}

@keyframes draw-line {
  from {
    stroke-dashoffset: 1040; /* Match this to the total length of your path */
  }
  to {
    stroke-dashoffset: 0;
  }
}


.path-animationTab {
  animation: draw-lineTab 8s forwards; /* Adjust time as needed */
}

@keyframes draw-lineTab {
  from {
    stroke-dashoffset: 830; /* Match this to the total length of your path */
  }
  to {
    stroke-dashoffset: 0;
  }
}


@keyframes shadowFade {
  from {
    box-shadow: none;
  }
  to {
    box-shadow: 0px 8px 60px 0px #00a0fc1a;
    
  }
}

.shadow-animate {
  animation: shadowFade 1s forwards;
}

.underlineSpan {
  color: #00a0fc !important;
  text-decoration: underline;
  cursor: pointer;
}
.boldfont {
  font-weight: 600;
  color: #8c90a5;
}
/* You can also add a slight shadow or other effects to make it pop more */
